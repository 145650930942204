<script setup lang="ts">
interface Props {
  colSpan?: 'span-1' | 'span-2';
  imagePath?: string;
  customClasses?: string;
}

const props = withDefaults(defineProps<Props>(), {
  colSpan: 'span-1',
  imagePath: '',
  customClasses: '',
});

const colClasses = computed(() => {
  return twMerge(
    cva('bg-white flex h-[244px] rounded-[10px] overflow-hidden shadow-md backdrop-blur-xl', {
      variants: {
        colSpan: {
          ['span-1']: 'col-span-4 sm:col-span-2 md:col-span-1 flex-col',
          ['span-2']: 'col-span-4 md:col-span-2',
        },
      },
    })({
      colSpan: props.colSpan,
    }),
    props.customClasses,
  );
});

const imgClasses = computed(() => {
  return twMerge(
    cva('', {
      variants: {
        colSpan: {
          ['span-1']: 'h-[110px]',
          ['span-2']: 'w-2/4',
        },
      },
    })({
      colSpan: props.colSpan,
    }),
  );
});

const contentClasses = computed(() => {
  return twMerge(
    cva('text-base', {
      variants: {
        colSpan: {
          ['span-1']: 'text-center p-2',
          ['span-2']: 'w-2/4 p-6 pr-8 pt-12',
        },
      },
    })({
      colSpan: props.colSpan,
    }),
  );
});
</script>

<template>
  <div :class="colClasses">
    <div :class="imgClasses">
      <NuxtImg class="object-cover w-full h-full" preload role="presentation" :src="imagePath" />
    </div>
    <div :class="contentClasses">
      <slot name="testimonial" />
    </div>
  </div>
</template>
