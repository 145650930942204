<script lang="ts" setup></script>

<template>
  <div class="bg-[url('/images/landing/passion.webp')] [background-position:22%] lg:bg-top 2xl:bg-bottom bg-cover">
    <div class="get-started container text-center h-[560px] px-4">
      <div class="max-w-[500px] px-6 py-16 mx-auto backdrop-blur-md bg-[rgba(251,251,251,0.23)] rounded-b-3xl">
        <UIHeading size="h2" styleSize="h2" customClasses="font-normal" class="title">
          <UIHtml :html="$t('landing.feelConfident')" />
        </UIHeading>
      </div>
    </div>
  </div>
</template>
